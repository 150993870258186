import React, { useRef, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { useAuth } from "../context/AuthProvider";
import { useNavigate } from "react-router-dom";

const UpdatePassword = () => {
  const { updatePassword } = useAuth();
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!passwordRef.current?.value || !confirmPasswordRef.current?.value) {
      setErrorMsg("Please fill all the fields");
      return;
    }
    if (passwordRef.current.value !== confirmPasswordRef.current.value) {
      setErrorMsg("Passwords doesn't match. Try again");
      return;
    }
    try {
      setErrorMsg("");
      setLoading(true);
      const { error } = await updatePassword(passwordRef.current.value);
      if (!error) {
        navigate("/");
      }
    } catch (error) {
      setErrorMsg("Error in Updating Password. Please try again");
    }
    setLoading(false);
  };

  return (
    <>
      <h3 className="text-center my-4">Neues Passwort setzen</h3>
      <Form onSubmit={handleSubmit}>
        <Form.Group id="password">
          <Form.Label>Passwort</Form.Label>
          <Form.Control type="password" ref={passwordRef} required />
        </Form.Group>
        <Form.Group id="confirm-password">
          <Form.Label>Passwort bestätigen</Form.Label>
          <Form.Control type="password" ref={confirmPasswordRef} required />
        </Form.Group>
        {errorMsg && (
          <Alert
            variant="danger"
            onClose={() => setErrorMsg("")}
            dismissible>
            {errorMsg}
          </Alert>
        )}
        <div className="text-center mt-2">
          <Button disabled={loading} type="submit" className="w-50">
            Speichern
          </Button>
        </div>
      </Form>
    </>
  );
};

export default UpdatePassword;