import { useEffect, useState } from "react";
import { Alert, Button, Form, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { supabase } from "../supabase/client";
import { ChevronDown, ChevronRight } from "react-bootstrap-icons";

const Home = () => {
    const [errorMsg, setErrorMsg] = useState("");
    function getSaturdayFortnight(hour) {
        let now = new Date();
        let next = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 13, hour, 0, 0, 0);
        return next;
    }

    const [myMarkets, setMyMarkets] = useState([]);

    const [showOthers, setShowOthers] = useState(false);
    const [otherMarkets, setOtherMarkets] = useState([]);

    const [showOld, setShowOld] = useState(false);
    const [showOldOthers, setShowOldOthers] = useState(false);
    const [oldMarkets, setOldMarkets] = useState([]);

    const [errorMsgCreate, setErrorMsgCreate] = useState("");
    const [showCreate, setShowCreate] = useState(false);
    const [state, setState] = useState({
        name: '',
        starts: getSaturdayFortnight(10),
        ends: getSaturdayFortnight(16),
        share_pct: 20,
        postal_code: '',
        address: ''
    });
    const [creating, setCreating] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        supabase.rpc("fm_my_markets").then((datasets) => {
            if (datasets?.data?.length != null) {
                setMyMarkets(datasets.data);
            }
        });
        supabase.rpc("fm_other_markets").then((datasets) => {
            if (datasets?.data?.length != null) {
                setOtherMarkets(datasets.data);
            }
        });
    }, []);

    useEffect(() => {
        supabase.rpc("fm_old_markets", { "_with_other_markets": (showOldOthers ?? false) }).then((datasets) => {
            if (datasets?.data?.length != null) {
                setOldMarkets(datasets.data);
            }
        });
    }, [showOldOthers]);

    const buildShowMarket = (marketId) => {
        return async (e) => {
            e?.preventDefault?.();
            navigate("/markt/" + marketId);
        }
    };

    const buildJoinMarket = (marketId) => {
        return async (e) => {
            e?.preventDefault?.();
            const { error } = await supabase.rpc('fm_join_market', { "_market_id": marketId });

            if (error) {
                setErrorMsg(error);
            } else {
                let markets = otherMarkets.filter(m => m.id === marketId);
                setOtherMarkets(otherMarkets.filter(m => m.id !== marketId));
                setMyMarkets([...myMarkets, ...markets]);
            }
        }
    };

    const dateToIsoString = (date) => {
        // yyyy-MM-ddThh:mm:ss+XX:XX
        var tzo = -date.getTimezoneOffset(),
            dif = tzo >= 0 ? '+' : '-',
            pad = function (num) {
                return (num < 10 ? '0' : '') + num;
            };

        return date.getFullYear() +
            '-' + pad(date.getMonth() + 1) +
            '-' + pad(date.getDate()) +
            'T' + pad(date.getHours()) +
            ':' + pad(date.getMinutes()) +
            ':' + pad(date.getSeconds()) +
            dif + pad(Math.floor(Math.abs(tzo) / 60)) +
            ':' + pad(Math.abs(tzo) % 60);
    };

    const createMarket = async (e) => {
        e?.preventDefault?.();
        setCreating(true);
        try {
            if (!state.name || !state.starts || !state.ends || !state.share_pct || !state.address) {
                console.error('Field missing');
                return;
            }

            let postal_code_id = null;
            if (state.postal_code) {
                console.log(`Find postal code id for ${state.postal_code}`);
                const { data, error } = await supabase.from('fm_postal_codes').select().eq('code', state.postal_code).single();
                if (error) {
                    console.error(`Find postal code id error: ${error}`);
                    setErrorMsgCreate(error);
                    return;
                } else if (data) {
                    console.log(`postal code found: ${JSON.stringify(data)}`);
                    postal_code_id = data.id;
                }
            }

            let record = {
                name: state.name,
                starts: dateToIsoString(state.starts),
                ends: dateToIsoString(state.ends),
                share_pct: state.share_pct,
                postal_code: postal_code_id,
                address: state.address
            };
            console.log(`insert market: ${JSON.stringify(record)}`);
            let { data, error } = await supabase.from("fm_markets").insert(record).select().single();
            if (error) {
                if (error.code && error.message) {
                    if (error.code === "23505") {
                        setErrorMsgCreate("Es gibt bereits einen Markt mit dieser Bezeichnung im Monat zur Postleitzahl. Bitte wähle einen anderen Namen, um Verwechslungen zu vermeiden.");
                    } else {
                        setErrorMsgCreate(error.message);
                    }
                }
                else {
                    setErrorMsgCreate(JSON.stringify(error));
                }
                return;
            }

            if (data?.id) {
                buildJoinMarket(data.id)();
                console.log(JSON.stringify([...myMarkets, data]));
                setMyMarkets([...myMarkets, data]);
            } else {
                console.log(`unknown result from creation: ${JSON.stringify(data)}`);
            }
        } finally {
            setCreating(false);
        }
    };

    function dateToInputString(date) {
        // yyyy-MM-ddThh:mm
        var pad = function (num) {
            return (num < 10 ? '0' : '') + num;
        };
        return date.getFullYear() +
            '-' + pad(date.getMonth() + 1) +
            '-' + pad(date.getDate()) +
            'T' + pad(date.getHours()) +
            ':' + pad(date.getMinutes());
    };

    function dateFromInputString(inputValue) {
        return new Date(inputValue);
    };

    const formatDate = (date) => {
        let datetime = date instanceof Date ? date : new Date(date);
        return datetime.toLocaleDateString([], { day: '2-digit', month: '2-digit', year: 'numeric' });
    };

    const formatTime = (date) => {
        let datetime = date instanceof Date ? date : new Date(date);
        return datetime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    };

    const formatDateTime = (date) => {
        let datetime = date instanceof Date ? date : new Date(date);
        return `${formatDate(datetime)} ${formatTime(datetime)}`;
    };

    const updateCreate = (e) => {
        if (e.target.name === 'name') {
            let newValue = e.target.value;
            setState({ ...state, name: newValue });
        } else if (e.target.name === 'starts') {
            let newStart = dateFromInputString(e.target.value);
            let newEnd = new Date(state.ends);
            if (newStart > newEnd) {
                newEnd.setTime(newEnd.getTime() + (newStart.getTime() - state.starts.getTime()));
            }
            setState({ ...state, starts: newStart, ends: newEnd });
        } else if (e.target.name === 'ends') {
            let newEnd = dateFromInputString(e.target.value);
            let newStart = new Date(state.starts);
            if (newStart > newEnd) {
                newStart.setTime(newStart.getTime() + (newEnd.getTime() - state.ends.getTime()));
            }
            setState({ ...state, starts: newStart, ends: newEnd });
        } else if (e.target.name === 'share_pct') {
            let newValue = e.target.value;
            setState({ ...state, share_pct: newValue });
        } else if (e.target.name === 'postal_code') {
            let newValue = e.target.value;
            setState({ ...state, postal_code: newValue });
        } else if (e.target.name === 'address') {
            let newValue = e.target.value;
            setState({ ...state, address: newValue });
        }
    };

    return (
        <>
            {errorMsg && (
                <Alert
                    variant="danger"
                    onClose={() => setErrorMsg("")}
                    dismissible>
                    {errorMsg}
                </Alert>
            )}
            <h2 className="my-4">Meine Flohmärkte</h2>
            {myMarkets.length ?
                (<Table hover striped>
                    <thead>
                        <tr>
                            <th>Flohmarkt</th>
                            <th>Datum</th>
                        </tr>
                    </thead>
                    <tbody>
                        {myMarkets.map((market, index) => (
                            <tr key={index} onClick={buildShowMarket(market.id)} className="cursor-pointer">
                                <td>{market.name}</td>
                                <td>{formatDate(market.starts)}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>) : (<div>Du bist noch keinen Märkten beigetreten</div>)
            }
            <h2 className="my-4 cursor-pointer" onClick={() => { setShowOthers(!showOthers) }}>{showOthers ? <ChevronDown size={20} /> : <ChevronRight size={20} />} Weitere Flohmärkte</h2>
            {otherMarkets.length ? (showOthers &&
                (<Table hover striped>
                    <thead>
                        <tr>
                            <th>Flohmarkt</th>
                            <th>Datum</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {otherMarkets.map((market, index) => (
                            <tr key={index}>
                                <td>{market.name}</td>
                                <td>{formatDate(market.starts)}</td>
                                <td><button onClick={buildJoinMarket(market.id)}>Beitreten</button></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>)) : (showOthers && (<div>Es gibt keine weiteren, relevanten Märkte.</div>))
            }
            <h2 className="my-4 cursor-pointer" onClick={() => setShowCreate(!showCreate)}>{showCreate ? <ChevronDown size={20} /> : <ChevronRight size={20} />} Neuen Flohmarkt anlegen</h2>
            {showCreate && errorMsgCreate && (
                <Alert
                    variant="danger"
                    onClose={() => setErrorMsgCreate("")}
                    dismissible>
                    {errorMsgCreate}
                </Alert>
            )}
            {showCreate &&
                <Form onSubmit={createMarket}>
                    <Form.Group id="new_market_name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" name="name" value={state.name} onChange={updateCreate} required />
                    </Form.Group>
                    <Form.Group id="new_market_start">
                        <Form.Label>Beginn</Form.Label>
                        <Form.Control type="datetime-local" name="starts" value={dateToInputString(state.starts)} onChange={updateCreate} required />
                    </Form.Group>
                    <Form.Group id="new_market_end">
                        <Form.Label>Ende</Form.Label>
                        <Form.Control type="datetime-local" name="ends" value={dateToInputString(state.ends)} onChange={updateCreate} required />
                    </Form.Group>
                    <Form.Group id="new_market_end">
                        <Form.Label>Anteil (%)</Form.Label>
                        <Form.Control type="number" min={0} max={100} step={1} name="share_pct" value={state.share_pct} onChange={updateCreate} required />
                    </Form.Group>
                    <Form.Group id="new_market_postcode">
                        <Form.Label>Postleitzahl</Form.Label>
                        <Form.Control type="text" name="postal_code" value={state.postal_code} onChange={updateCreate} />
                    </Form.Group>
                    <Form.Group id="new_market_address">
                        <Form.Label>Adresse</Form.Label>
                        <Form.Control as="textarea" rows={3} name="address" value={state.address} onChange={updateCreate} required />
                    </Form.Group>
                    <div className="text-center mt-2">
                        <Button disabled={creating} type="submit" className="w-50">
                            Erstellen
                        </Button>
                    </div>
                </Form>
            }
            <h2 className="my-4 cursor-pointer" onClick={() => { setShowOld(!showOld) }}>{showOld ? <ChevronDown size={20} /> : <ChevronRight size={20} />} Alte Flohmärkte</h2>
            {
                showOld &&
                (<>
                    <Form.Check type="checkbox" label="Auch fremde Märkte anzeigen?" value={showOldOthers ?? false} onChange={() => { setShowOldOthers(!showOldOthers) }} />
                    {
                        oldMarkets.length ?
                            (<Table hover striped>
                                <thead>
                                    <tr>
                                        <th>Flohmarkt</th>
                                        <th>Beginn</th>
                                        <th>Ende</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {oldMarkets.map((market, index) => (
                                        <tr key={index}>
                                            <td>{market.name}</td>
                                            <td>{formatDateTime(market.starts)}</td>
                                            <td>{formatDateTime(market.ends)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>) : (<div>Es gibt keine alten Märkte.</div>)
                    }
                </>)
            }
        </>
    );
};

export default Home;