import React from "react";
import UpdatePassword from "../components/UpdatePassword";
import { Card } from "react-bootstrap";
import { useAuth } from "../context/AuthProvider";
import { BarLoader } from "react-spinners";

const UpdatePasswordPage = () => {
  const { user } = useAuth();
  return (
    <>
      {!user && (<div className="d-flex align-items-center justify-content-center"
        style={{
          minHeight: "20px",
          flexGrow: "1"
        }}
      >
        <BarLoader />
      </div>)}
      {user && (
        <>
          <Card>
            <Card.Body>
              <UpdatePassword />
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};

export default UpdatePasswordPage;