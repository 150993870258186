import { useEffect, useRef, useState } from "react";
import { Alert, Breadcrumb, Button, Card, Col, Form, InputGroup, Nav, Row, Table } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import { useParams } from "react-router";
import { supabase } from "../supabase/client";
import { Link } from "react-router-dom";
import { BarLoader } from "react-spinners";

const Checkout = () => {
    const LS_ITEMS = 'flohmarkt_unsaved_data';
    const LS_ITEM_RECEIPT = 'receipt';
    const LS_ITEM_ITEMS = 'items';
    const LS_PREFS = 'flohmarkt_prefs';
    const INTERVAL_RETRY_STORAGE_CHECK = 60000; // every minute

    const getLocalStorageJson = (key, defaultValue) => {
        const textFromStorage = localStorage.getItem(key);
        const data = textFromStorage ? JSON.parse(textFromStorage) : defaultValue;
        return data;
    };

    const setLocalStorageJson = (key, obj) => {
        localStorage.setItem(key, JSON.stringify(obj));
    };

    const saveItemLocally = (item) => {
        const data = getLocalStorageJson(LS_ITEMS, []);
        data.push(item);
        setLocalStorageJson(LS_ITEMS, data);
    }

    const saveData = async (receiptData, boughtItemsData) => {
        let { error } = await supabase.from("fm_receipts").insert(receiptData);
        if (error) { throw new Error(error); }
        let { itemsError } = await supabase.from("fm_items_bought").insert(boughtItemsData);
        if (itemsError) { throw new Error(itemsError); }
    };

    let { id: _market_id } = useParams();

    const [market, setMarket] = useState(null);

    const [errorMsg, setErrorMsg] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [loadingErrorMsg, setLoadingErrorMsg] = useState("");
    const [loading, setLoading] = useState(true);

    const [terminal_nr, setTerminalNr] = useState(null);
    const [cash_desk_nr, setCashDeskNr] = useState(1);
    const sellerNumRef = useRef(null);
    const itemCostRef = useRef(null);

    const [items, setItems] = useState([]);

    useEffect(() => {
        const fetchMarket = async () => {
            const { data, error } = await supabase.from('fm_markets').select().eq('id', Number(_market_id)).single();
            if (error) {
                throw new Error(error);
            } else if (data) {
                setMarket(data);
            }
        };

        const retrieveCashDeskNr = async () => {
            let prefs = getLocalStorageJson(LS_PREFS, {});
            if (!prefs.terminal_id) {
                const { data, error } = await supabase.rpc('fm_next_cash_desk_nr');
                if (error) {
                    throw new Error(error);
                } else if (data) {
                    prefs.terminal_id = Number(data);
                    setLocalStorageJson(LS_PREFS, prefs);
                }
            }
            setTerminalNr(prefs.terminal_id);
        };

        setLoading(true);
        Promise.all([fetchMarket(), retrieveCashDeskNr()])
            .then(() => setLoading(false))
            .catch(e => setLoadingErrorMsg(e.message));
    }, [_market_id]);

    const resetDisplayItem = () => {
        sellerNumRef.current.value = '';
        itemCostRef.current.value = '';
        sellerNumRef.current.focus();
    };

    useEffect(() => {
        const checkInterval = setInterval(async () => {
            let storageData = getLocalStorageJson(LS_ITEMS, []);
            let savingAll = storageData.map(d => { return { data: d, promise: saveData(d[LS_ITEM_RECEIPT], d[LS_ITEM_ITEMS]) }; });
            // if everything worked fine, delete local storage
            Promise
                .all(savingAll.map(l => l.promise))
                .then(() => {
                    // check all promises and keep items of rejected ones
                    let remainingItems = savingAll.filter(l => l.promise.status === 'rejected').map(l => l.data);
                    // ideally, remainingItems is empty
                    setLocalStorageJson(LS_ITEMS, remainingItems);
                });
        }, INTERVAL_RETRY_STORAGE_CHECK);

        return () => clearInterval(checkInterval);
    }, []);

    const addItem = (sellerNum, itemCost) => {
        setItems([...items, { seller: sellerNum, price: itemCost }]);
    };

    const onRemoveItem = (index) => {
        return () => {
            setItems(items.filter((i, idx) => idx !== index))
        };
    }

    const [handleItemSubmit, handleCheckout] = (() => {
        const start = () => {
            setErrorMsg("");
            setSubmitting(true);
        };

        const stop = () => {
            setSubmitting(false);
        }

        const fail = (msg) => {
            setErrorMsg(msg);
            stop();
        };

        const success = () => {
            resetDisplayItem();
            stop();
        };

        const overallSuccess = () => {
            setItems([]);
            success();
        }

        const valueMissing = (refCurrent, name) => {
            return (!refCurrent?.value && refCurrent?.value !== 0);
        };

        const failWhenMissing = (valueMissing, refCurrent, name) => {
            if (valueMissing) {
                fail(`Bitte fülle das Feld "${name}" aus.`);
                refCurrent?.focus?.();
            }
            return valueMissing;
        };

        const handleCheckout = (e) => {
            e?.preventDefault?.();

            const generateData = () => {
                let receiptData = {
                    id: crypto.randomUUID(),
                    market_id: _market_id,
                    terminal_nr,
                    cash_desk_nr
                };

                let boughtItemsData = items.map((item, idx) => {
                    return {
                        item_no: idx + 1,
                        seller: item.seller,
                        price: item.price,
                        receipt_id: receiptData.id
                    };
                });
                return [receiptData, boughtItemsData];
            };

            const saveInLocalStorage = (receiptData, boughtItemsData) => {
                let newItem = {};
                newItem[LS_ITEM_RECEIPT] = receiptData;
                newItem[LS_ITEM_ITEMS] = boughtItemsData;
                saveItemLocally(newItem);
            }

            const pushData = async () => {
                let [receiptData, boughtItemsData] = generateData();
                try {
                    await saveData(receiptData, boughtItemsData)
                }
                catch (e) {
                    console.log(`Error saving data: ${e}`);
                    // retry with new uuid
                    [receiptData, boughtItemsData] = generateData();
                    try {
                        await saveData(receiptData, boughtItemsData);
                    }
                    catch (e2) {
                        console.log(`Error saving data: ${e2}`);
                        saveInLocalStorage(receiptData, boughtItemsData);
                    }
                }
            };

            start();
            pushData().then(overallSuccess).catch(() => {
                fail("Die Daten konnten nicht gespeichert werden");
            });
        };

        const handleItemSubmit = async (e) => {
            e?.preventDefault?.();

            function ask4checkout() {
                return items?.length && window.confirm('Zum nächsten Kunden wechseln?');
            }

            start();

            let sellerNumMiss = valueMissing(sellerNumRef.current);
            let itemCostMiss = valueMissing(itemCostRef.current);

            if (sellerNumMiss && itemCostMiss && ask4checkout()) {
                handleCheckout();
                return;
            } else if (failWhenMissing(sellerNumMiss, sellerNumRef.current, 'Verkäufer')
                || failWhenMissing(itemCostMiss, itemCostRef.current, 'Preis')) {
                return;
            }

            try {
                addItem(Number(sellerNumRef.current.value), Number(itemCostRef.current.value));
                success();
            }
            catch (e) {
                fail("Bitte gibt nur gültige Zahlen in die Felder ein.")
            } finally {
                stop();
            }
        };

        return [handleItemSubmit, handleCheckout];
    })();

    const largeTextStyle = { fontSize: "1.4em" };

    const roundAmount = (amount) => {
        return Math.round(amount * 100) / 100;
    };

    const formatAmount = (amount) => roundAmount(amount).toFixed(2);

    return (
        <>
            {loading && (<div className="d-flex align-items-center justify-content-center"
                style={{
                    minHeight: "20px",
                    flexGrow: "1"
                }}
            >
                <BarLoader />
                {loadingErrorMsg && (
                    <Alert
                        variant="danger"
                        onClose={() => setLoadingErrorMsg("")}
                        dismissible>
                        {loadingErrorMsg}
                    </Alert>
                )}
            </div>)}
            {!loading && (<>
                <Breadcrumb>
                    <Breadcrumb.Item linkAs={Nav.Link} linkProps={{ as: Link, to: "/" }}>Märkte</Breadcrumb.Item>
                    <Breadcrumb.Item linkAs={Nav.Link} linkProps={{ as: Link, to: "/markt/" + _market_id }}>{market?.name?.replaceAll(' ', '\u00a0')}</Breadcrumb.Item>
                    <Breadcrumb.Item active>Kasse</Breadcrumb.Item>
                </Breadcrumb>
                <Card>
                    <Card.Body>
                        <h2 className="text-center mb-4">
                            Kasse <input style={{ textAlign: "right", border: "none" }} type="number" min="1" max="100" step="1" value={cash_desk_nr} onChange={(e) => { setCashDeskNr(e.target.value); }} />
                        </h2>
                        <Form onSubmit={handleItemSubmit}>
                            <Row>
                                <Col>
                                    <Form.Control autoFocus type="number" min="1" placeholder="Verkäufer" ref={sellerNumRef} />
                                </Col>
                                <Col>
                                    <InputGroup className="mb-2">
                                        <Form.Control type="number" min="0.01" step="0.01" placeholder="Preis" ref={itemCostRef} />
                                        <InputGroup.Text>€</InputGroup.Text>
                                    </InputGroup>
                                </Col>
                            </Row>
                            {errorMsg && (
                                <Alert
                                    variant="danger"
                                    onClose={() => setErrorMsg("")}
                                    dismissible>
                                    {errorMsg}
                                </Alert>
                            )}
                            <div className="text-center mt-2">
                                <Button disabled={submitting} type="submit" className="w-50">
                                    Eintragen
                                </Button>
                            </div>
                        </Form>
                        {items?.length ? (
                            <>
                                <div className="mt-5" style={largeTextStyle}>
                                    <Table>
                                        <tbody>
                                            <tr>
                                                <th>Gesamt:</th>
                                                <th>{formatAmount(items.reduce((s, i) => s + i.price, 0))} €</th>
                                            </tr>
                                            <tr>
                                                <th>Artikel:</th>
                                                <td>{items.length}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                                <div className="text-center mt-2">
                                    <Button disabled={submitting} onClick={handleCheckout} className="w-50">
                                        Nächster Kunde
                                    </Button>
                                </div>
                                <div className="mt-5" style={largeTextStyle}>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Verkäufer</th>
                                                <th>Preis</th>
                                                <th>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {items.slice().reverse().map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.seller}</td>
                                                    <td>{formatAmount(item.price)}</td>
                                                    <td>
                                                        <div><Trash size={42} onClick={onRemoveItem(items.length - 1 - index)} className="p-2 border border-2 rounded cursor-pointer" /></div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </>
                        ) : (<div className="py-3 text-center">Warenkorb ist noch leer...</div>)}
                    </Card.Body>
                </Card>
            </>)}
        </>
    );
};

export default Checkout;
