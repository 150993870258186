import { Container } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import Register from "./pages/Register";
import Login from "./pages/Login";
import AuthRoute from "./components/AuthRoute";
import Home from "./pages/Home";
import Market from "./pages/Market";
import NavBar from "./components/NavBar";
import Checkout from "./pages/Checkout";
import Evaluation from "./pages/Evaluation";
import PasswordReset from "./pages/PasswordReset";
import ManageMarket from "./pages/ManageMarket";
import Profile from "./pages/Profile";
import UpdatePasswordPage from "./pages/UpdatePasswordPage";

const App = () => {
  return (
    <>
      <Container
        className="d-flex justify-content-center"
        style={{ minHeight: "100vh" }}>
        <div className="w-100 d-flex flex-column" style={{ maxWidth: "clamp(400px, 100vw, 600px)", minHeight: "50vh" }}>
          <NavBar />
          <Routes>
            <Route element={<AuthRoute />}>
              <Route path="/" element={<Home />} />
              <Route path="/maerkte" element={<Home />} />
              <Route path="/profil" element={<Profile />} />
              <Route path="/markt/:id" element={<Market />} />
              <Route path="/kasse/:id" element={<Checkout />} />
              <Route path="/auswertung/:id" element={<Evaluation />} />
              <Route path="/manage/:id" element={<ManageMarket />} />
            </Route>
            <Route path="/update-password" element={<UpdatePasswordPage />} />
            <Route path="/passwordreset" element={<PasswordReset />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </div>
      </Container>
    </>
  );
};

export default App;