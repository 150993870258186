import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { supabase } from "../supabase/client";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

export class Role {
  static Owner = new Role('owner', 'BesitzerIn', 1);
  static Admin = new Role('admin', 'Admin', 2);
  static Cashier = new Role('cashier', 'KassiererIn', 3);
  static User = new Role('user', 'Gast', 4);
  static Unknown = new Role('unknown', 'Unbekannt', 99);

  constructor(key, title, prio) {
    this.key = key;
    this.title = title;
    this.prio = prio;
  }

  static get(roleKey) {
    let matches = Object.keys(Role).map(c => Role[c]).filter(r => r.key === roleKey);
    return matches?.length ? matches[0] : Role.Unknown;
  }

  static getTitleFor(roleKey) {
    return Role.get(roleKey).title;
  }

  static canSell(role) {
    return (role?.prio ?? Role.Unknown.prio) <= Role.Cashier.prio;
  }

  static canEval(role) {
    return (role?.prio ?? Role.Unknown.prio) <= Role.Admin.prio;
  }

  static canManage(role) {
    return (role?.prio ?? Role.Unknown.prio) <= Role.Admin.prio;
  }
}

const login = (email, password) =>
  supabase.auth.signInWithPassword({ email, password });

const logout = () => supabase.auth.signOut();

const passwordReset = (email) =>
  supabase.auth.resetPasswordForEmail(email, {
    redirectTo: `${window.location.origin}/#/update-password`
  });

const updatePassword = (updatedPassword) =>
  supabase.auth.updateUser({ password: updatedPassword });

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(null);
  const [user, setUser] = useState(null);
  const [auth, setAuth] = useState(false);
  const [roles, setRoles] = useState([]);

  const refreshRoles = useCallback((user) => {
    if (user?.id) {
      async function fetchRoles() {
        const { data, error } = await supabase.from('fm_roles').select().eq('user_id', user.id);
        if (error) {
          console.error(error);
        } else if (data?.length) {
          setRoles(data);
        }
      }
      fetchRoles();
    } else {
      setRoles([]);
    }
  }, []);

  const getRole = useCallback((_market_id) => {
    let marketId = _market_id instanceof Number ? _market_id : Number(_market_id);
    let marketRoles = roles.filter(r => r.market_id === marketId);
    if (marketRoles?.length) {
      return Role.get(marketRoles[0].role);
    }
    return Role.User;
  }, [roles]);

  useEffect(() => {
    setLoading(true);
    const getUser = async () => {
      const { data } = await supabase.auth.getUser();
      const { user: currentUser } = data;
      setUser(currentUser ?? null);
      setLoading(false);
    };
    getUser();
  }, []);

  useEffect(() => {
    const { data } = supabase.auth.onAuthStateChange((event, session) => {
      // can be: SIGNED_IN, SIGNED_OUT, TOKEN_REFRESHED, USER_UPDATED, PASSWORD_RECOVERY
      if (event === "PASSWORD_RECOVERY") {
        setAuth(false);
        refreshRoles(null);
      } else if (event === "SIGNED_IN") {
        setUser(session.user);
        setAuth(true);
        refreshRoles(session.user);
        navigate('/maerkte');
      } else if (event === "SIGNED_OUT") {
        setUser(null);
        setAuth(false);
        refreshRoles(null);
      } else if (event === "USER_UPDATED") {
        setUser(session.user);
        refreshRoles(session.user);
        navigate('/maerkte');
      }
    });
    return () => {
      data.subscription.unsubscribe();
    };
  }, [refreshRoles, navigate]);

  return (
    <AuthContext.Provider value={{ auth, user, getRole, login, logout, passwordReset, updatePassword }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;