import { useEffect, useState } from "react";
import { Card, Alert, Table, Form, Button, Breadcrumb, Nav } from "react-bootstrap";
import { supabase } from "../supabase/client";
import { useParams } from "react-router";
import { Role, useAuth } from "../context/AuthProvider";
import { Link } from "react-router-dom";

const ManageMarket = () => {
    let { id: _market_id } = useParams();

    const { getRole } = useAuth();

    const [loading, setLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState(null);

    const [marketName, setMarketName] = useState(null);
    const [starts, setStarts] = useState(null);
    const [ends, setEnds] = useState(null);
    const [postal_code, setPostalCode] = useState(null);
    const [address, setAddress] = useState(null);
    const [share_pct, setSharePct] = useState(null);

    const [userRole, setUserRole] = useState(null);
    const [participants, setParticipants] = useState(null);

    useEffect(() => {
        let active = 0;
        const fetchMarket = async () => {
            setLoading(true);
            active++;
            const { data, error } = await supabase.from('fm_markets').select("*, postal_code(*)").eq('id', Number(_market_id)).single();
            if (error) {
                setErrorMsg(error);
            } else if (data) {
                setMarketName(data.name);
                setStarts(new Date(data.starts));
                setEnds(new Date(data.ends));
                setPostalCode(data.postal_code.code);
                setAddress(data.address);
                setSharePct(data.share_pct);
            }
            active--;
            if (active < 1) {
                setLoading(false);
            }
        };
        const fetchParticipants = async () => {
            setLoading(true);
            active++;
            const { data, error } = await supabase.rpc('fm_participants', { _market_id: Number(_market_id) });
            if (error) {
                setErrorMsg(error);
            } else if (data?.length) {
                setParticipants(data);
            }
            active--;
            if (active < 1) {
                setLoading(false);
            }
        };
        fetchMarket();
        fetchParticipants();
    }, [_market_id]);

    useEffect(() => {
        setUserRole(getRole(_market_id));
    }, [getRole, _market_id]);

    const toInputString = (date) => {
        // yyyy-MM-ddThh:mm
        var pad = function (num) {
            return (num < 10 ? '0' : '') + num;
        };
        return date.getFullYear() +
            '-' + pad(date.getMonth() + 1) +
            '-' + pad(date.getDate()) +
            'T' + pad(date.getHours()) +
            ':' + pad(date.getMinutes());
    };

    const fromInputString = (inputValue) => {
        return new Date(inputValue);
    };

    const toIsoString = (date) => {
        // yyyy-MM-ddThh:mm:ss+XX:XX
        var tzo = -date.getTimezoneOffset(),
            dif = tzo >= 0 ? '+' : '-',
            pad = function (num) {
                return (num < 10 ? '0' : '') + num;
            };

        return date.getFullYear() +
            '-' + pad(date.getMonth() + 1) +
            '-' + pad(date.getDate()) +
            'T' + pad(date.getHours()) +
            ':' + pad(date.getMinutes()) +
            ':' + pad(date.getSeconds()) +
            dif + pad(Math.floor(Math.abs(tzo) / 60)) +
            ':' + pad(Math.abs(tzo) % 60);
    };

    const updateMarket = async (event) => {
        event.preventDefault();

        setLoading(true);

        let postal_code_id = null;
        await (async () => {
            const { data, error } = await supabase.from('fm_postal_codes').select().eq('code', postal_code).single();
            if (error) {
                setErrorMsg(error);
            } else if (data) {
                postal_code_id = data.id;
            }
        })();

        const updates = {
            id: _market_id,
            name: marketName,
            starts: toIsoString(starts),
            ends: toIsoString(ends),
            postal_code: postal_code_id,
            address,
            share_pct
        };

        const { error } = await supabase.from('fm_markets').upsert(updates);

        if (error) {
            setErrorMsg(error);
        }
        setLoading(false);
    }

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item linkAs={Nav.Link} linkProps={{ as: Link, to: "/" }}>Märkte</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Nav.Link} linkProps={{ as: Link, to: "/markt/" + _market_id }}>{marketName || 'Unbekannt'}</Breadcrumb.Item>
                <Breadcrumb.Item active>Einstellungen</Breadcrumb.Item>
            </Breadcrumb>
            <Card>
                <Card.Body>
                    {(loading || !Role.canManage(userRole)) && (
                        <p>Berechtigungen werden geladen oder sind unzureichend.</p>
                    )}
                    {!loading && Role.canManage(userRole) && (<>
                        <h2 className="text-center mb-4">Einstellungen</h2>
                        <Form onSubmit={updateMarket}>
                            <Form.Group id="marketName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" value={marketName || ''} onChange={(e) => setMarketName(e.target.value)} required />
                            </Form.Group>
                            <Form.Group id="starts">
                                <Form.Label>Beginn</Form.Label>
                                <Form.Control type="datetime-local" value={toInputString(starts) || ''} onChange={(e) => setStarts(fromInputString(e.target.value))} required />
                            </Form.Group>
                            <Form.Group id="ends">
                                <Form.Label>Ende</Form.Label>
                                <Form.Control type="datetime-local" value={toInputString(ends) || ''} onChange={(e) => setEnds(fromInputString(e.target.value))} required />
                            </Form.Group>
                            <Form.Group id="postal_code">
                                <Form.Label>Postleitzahl</Form.Label>
                                <Form.Control type="text" value={postal_code || ''} onChange={(e) => setPostalCode(e.target.value)} />
                            </Form.Group>
                            <Form.Group id="address">
                                <Form.Label>Adresse</Form.Label>
                                <Form.Control type="text" value={address || ''} onChange={(e) => setAddress(e.target.value)} />
                            </Form.Group>
                            <Form.Group id="share_pct">
                                <Form.Label>Anteil am Umsatz</Form.Label>
                                <Form.Control type="number" min="0" max="100" step="1" value={share_pct || ''} onChange={(e) => setSharePct(e.target.value)} />
                            </Form.Group>
                            {errorMsg && (
                                <Alert
                                    variant="danger"
                                    onClose={() => setErrorMsg("")}
                                    dismissible>
                                    {errorMsg}
                                </Alert>
                            )}
                            <div className="text-center mt-2">
                                <Button disabled={loading} type="submit" className="w-50">
                                    Speichern
                                </Button>
                            </div>
                        </Form>
                        {participants && (<>
                            <h2 className="text-center mt-4">Beteiligte</h2>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Rolle</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {participants.map((user, index) => (
                                        <tr key={index}>
                                            <td>{user.name}</td>
                                            <td>{Role.getTitleFor(user.role)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </>)}
                    </>)}
                </Card.Body>
            </Card>
        </>
    );
};

export default ManageMarket;