import { useEffect, useState } from "react";
import { Card, Alert, Table } from "react-bootstrap";
import { supabase } from "../supabase/client";
import { useNavigate, useParams } from "react-router";
import { Role, useAuth } from "../context/AuthProvider";
import { Link } from "react-router-dom";
import { BarLoader } from "react-spinners";

const Market = () => {
    let { id: _market_id } = useParams();

    const [loading, setLoading] = useState(true);
    const [loadingErrorMsg, setLoadingErrorMsg] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);

    const [market, setMarket] = useState(null);
    //const [userRole, setUserRole] = useState(null);

    const { user, getRole } = useAuth();
    const navigate = useNavigate();

    let userRole = getRole(_market_id);

    useEffect(() => {
        const fetchMarket = async () => {
            const { data, error } = await supabase.from('fm_markets').select("*, postal_code(*)").eq('id', Number(_market_id)).single();
            if (error) {
                throw new Error(error);
            } else if (data) {
                setMarket(data);
            }
        };
        /*const loadRoles = async () => {
            refreshRoles();
            setUserRole(getRole(_market_id));
        };*/

        setLoading(true);
        Promise.all([fetchMarket()]) // , loadRoles()
            .then(() => setLoading(false))
            .catch(e => setLoadingErrorMsg(e.message));
    }, [_market_id]);

    const leaveMarket = async () => {
        if (user?.id && _market_id && window.confirm("Willst du wirklich austreten?")) {
            const { error } = await supabase.rpc('fm_leave_market', { "_market_id": _market_id });
            if (error) {
                setErrorMsg(error);
            } else {
                navigate('/');
            }
        }
    };

    const formatDate = (dtString) => {
        if (!dtString) return "";

        let datetime = new Date(dtString);
        return datetime.toLocaleDateString([], { day: '2-digit', month: '2-digit', year: 'numeric' });
    };

    const formatTime = (dtString) => {
        if (!dtString) return "";

        let datetime = new Date(dtString);
        return datetime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    };

    return (
        <>
            {loading && (<div className="d-flex align-items-center justify-content-center"
                style={{
                    minHeight: "20px",
                    flexGrow: "1"
                }}
            >
                <BarLoader />
                {loadingErrorMsg && (
                    <Alert
                        variant="danger"
                        onClose={() => setLoadingErrorMsg("")}
                        dismissible>
                        {loadingErrorMsg}
                    </Alert>
                )}
            </div>)}
            {!loading && (
                <>
                    <Card>
                        <Card.Body>
                            <h2 className="text-center mb-4">{market?.name}</h2>
                            {market && (<Table>
                                <tbody>
                                    <tr>
                                        <th>Beginn</th>
                                        <td>{formatDate(market.starts)}</td>
                                        <td>{formatTime(market.starts)}</td>
                                    </tr>
                                    <tr>
                                        <th>Ende</th>
                                        <td>{formatDate(market.ends)}</td>
                                        <td>{formatTime(market.ends)}</td>
                                    </tr>
                                    <tr>
                                        <th>PLZ</th>
                                        <td colSpan={"2"}>{market.postal_code?.code}</td>
                                    </tr>
                                    <tr>
                                        <th>Adresse</th>
                                        <td colSpan={"2"}>{market.address}</td>
                                    </tr>
                                    <tr>
                                        <th>Deine Rolle</th>
                                        <td colSpan={"2"}>{userRole.title}</td>
                                    </tr>
                                </tbody>
                            </Table>)}
                            {errorMsg && (
                                <Alert
                                    variant="danger"
                                    onClose={() => setErrorMsg("")}
                                    dismissible>
                                    {errorMsg}
                                </Alert>
                            )}
                            {Role.canSell(userRole) && (
                                <Link className="btn btn-outline-primary btn-lg w-100 mt-2" role="button" to={'/kasse/' + _market_id}>
                                    Kasse öffnen
                                </Link>
                            )}
                            {Role.canEval(userRole) && (
                                <Link className="btn btn-outline-primary btn-lg w-100 mt-2" role="button" to={'/auswertung/' + _market_id}>
                                    Auswertung ansehen
                                </Link>
                            )}
                            {Role.canManage(userRole) && (
                                <Link className="btn btn-outline-primary btn-lg w-100 mt-5" role="button" to={'/manage/' + _market_id}>
                                    Einstellungen
                                </Link>
                            )}
                            <Link className="btn btn-outline-danger btn-lg w-100 mt-5" role="button" onClick={leaveMarket}>
                                Flohmarkt verlassen
                            </Link>
                        </Card.Body>
                    </Card>
                </>
            )}
        </>
    );
};

export default Market;