import { useEffect, useState } from "react";
import { Alert, Breadcrumb, Card, Nav, Table } from "react-bootstrap";
import { useParams } from "react-router";
import { supabase } from "../supabase/client";
import { Link } from "react-router-dom";
import { BarLoader } from "react-spinners";

const Evaluation = () => {
    let { id: _market_id } = useParams();

    const [errorMsg, setErrorMsg] = useState("");
    const [loading, setLoading] = useState(true);

    const [market, setMarket] = useState(null);
    const [items, setItems] = useState(null);
    const [cash_desk_stats, setCashDeskStats] = useState(null);

    useEffect(() => {
        const fetchMarket = async () => {
            const { data, error } = await supabase.from('fm_markets').select().eq('id', Number(_market_id)).single();
            if (error) {
                throw new Error(error);
            } else if (data) {
                setMarket(data);
            }
        };
        const fetchSoldItems = async () => {
            const { data, error } = await supabase.rpc("fm_sold_items", { "_market_id": _market_id });
            if (error) {
                throw new Error(error);
            } else if (data) {
                setItems(data);
            }
        };
        const fetchStatistics = async () => {
            const { data, error } = await supabase.rpc("fm_cash_desk_statistics", { "_market_id": _market_id });
            if (error) {
                throw new Error(error);
            } else if (data) {
                setCashDeskStats(data);
            }
        };

        setLoading(true);
        Promise.all([fetchMarket(), fetchSoldItems(), fetchStatistics()])
            .then(() => setLoading(false))
            .catch(e => setErrorMsg(e.message));
    }, [_market_id]);

    const roundAmount = (amount) => {
        return Math.round(amount * 100) / 100;
    };

    const roundAmountPart = (amount, part) => {
        return part ? Math.round(amount * Number(part)) / 100 : 0;
    };

    const roundAmountRest = (amount, part) => {
        return roundAmount(roundAmount(amount) - roundAmountPart(amount, part));
    };

    const formatAmount = (amount) => amount.toFixed(2);
    const formatAmountPart = (amount, part) => formatAmount(roundAmountPart(amount, part));
    const formatAmountRest = (amount, part) => formatAmount(roundAmountRest(amount, part));
    const formatDateTime = (date) => new Date(date).toLocaleDateString([], { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false });

    return (
        <>
            {loading && (<div className="d-flex align-items-center justify-content-center"
                style={{
                    minHeight: "20px",
                    flexGrow: "1"
                }}
            >
                <BarLoader />
                {errorMsg && (
                    <Alert
                        variant="danger"
                        onClose={() => setErrorMsg("")}
                        dismissible>
                        {errorMsg}
                    </Alert>
                )}
            </div>)}
            {!loading && (<>
                <Breadcrumb>
                    <Breadcrumb.Item linkAs={Nav.Link} linkProps={{ as: Link, to: "/" }}>Märkte</Breadcrumb.Item>
                    <Breadcrumb.Item linkAs={Nav.Link} linkProps={{ as: Link, to: "/markt/" + _market_id }}>{market?.name?.replaceAll(' ', '\u00a0')}</Breadcrumb.Item>
                    <Breadcrumb.Item active>Auswertung</Breadcrumb.Item>
                </Breadcrumb>
                <Card>
                    <Card.Body className="overflow-scroll">
                        <h2 className="text-center mb-4">Auswertung</h2>
                        <div>Anteil des Flohmarkts: {market?.share_pct}%</div>
                        {items?.length ?
                            (<>
                                <Table hover striped>
                                    <thead>
                                        <tr>
                                            <th>Verkäufer</th>
                                            <th className={"text-end"}>Umsatz</th>
                                            <th className={"text-end"}>Anteil</th>
                                            <th className={"text-end"}>Ausgabe</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.seller}</td>
                                                <td className={"text-end"}>{formatAmount(item.sum)} €</td>
                                                <td className={"text-end"}>{formatAmountPart(item.sum, market?.share_pct)} €</td>
                                                <td className={"text-end"}>{formatAmountRest(item.sum, market?.share_pct)} €</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>Gesamt:</th>
                                            <th className={"text-end"}>{formatAmount(items.reduce((s, i) => s + i.sum, 0))} €</th>
                                            <th className={"text-end"}>{formatAmountPart(items.reduce((s, i) => s + i.sum, 0), market?.share_pct)} €</th>
                                            <th className={"text-end"}>{formatAmountRest(items.reduce((s, i) => s + i.sum, 0), market?.share_pct)} €</th>
                                        </tr>
                                        {cash_desk_stats?.length &&
                                            cash_desk_stats
                                                .filter(stat => stat.cash_desk_nr != null)
                                                .map((item, index) => (
                                                    <tr key={items.length+index}>
                                                        <th>Kasse #{item.cash_desk_nr}</th>
                                                        <td className={"text-end"}>{formatAmount(item.sum)} €</td>
                                                        <td className={"text-end"}>{formatAmountPart(item.sum, market?.share_pct)} €</td>
                                                        <td className={"text-end"}>{formatAmountRest(item.sum, market?.share_pct)} €</td>
                                                    </tr>
                                                ))}
                                    </tfoot>
                                </Table>
                            </>) : (<div>Es wurden noch keine Verkäufe erfasst.</div>)
                        }
                        <h3 className="text-center my-4">Kassen-Statistiken</h3>
                        {cash_desk_stats?.length ? (<Table hover striped className="text-end">
                            <thead>
                                <tr>
                                    <th>Kasse</th>
                                    <th>Geräte</th>
                                    <th>Kunden</th>
                                    <th>Teile</th>
                                    <th>Geöffnet</th>
                                    <th>Geschlossen</th>
                                </tr>
                            </thead>
                            <tbody className="text-end">
                                {cash_desk_stats.map((item, index) => {
                                    return item.cash_desk_nr != null ?
                                        (<tr key={index}>
                                            <td className="h3">{item.cash_desk_nr ? `#${item.cash_desk_nr}` : 'Gesamt'}</td>
                                            <td>{item.terminal_count}</td>
                                            <td>{item.customer_count}</td>
                                            <td>{item.item_count}</td>
                                            <td>{formatDateTime(item.first_item_at)}</td>
                                            <td>{formatDateTime(item.last_item_at)}</td>
                                        </tr>)
                                        :
                                        (<tr key={index}>
                                            <th>Gesamt</th>
                                            <th>{item.terminal_count}</th>
                                            <th>{item.customer_count}</th>
                                            <th>{item.item_count}</th>
                                            <th>{formatDateTime(item.first_item_at)}</th>
                                            <th>{formatDateTime(item.last_item_at)}</th>
                                        </tr>)
                                }
                                )}
                            </tbody>
                        </Table>) : (<div>Es wurden noch keine Verkäufe erfasst.</div>)
                        }
                    </Card.Body>
                </Card>
            </>)}
        </>
    );
};

export default Evaluation;