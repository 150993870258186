import { useRef, useState } from "react";
import { useAuth } from "../context/AuthProvider";
import { Alert, Button, Card, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

const PasswordReset = () => {
  const { passwordReset } = useAuth();
  const emailRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { data, error } = await passwordReset(emailRef.current.value);
      console.log(error);
      console.log(data);
      setMsg("Es wurde eine E-Mail an deinen Adresse geschickt, falls diese hier hinterlegt ist. Bitte schaue ggf. auch, ob die E-Mail im Spam gelandet ist und ob es sich um die korrekt, für den Account genutzte E-Mail-Adresse handelt.");
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Passwort vergessen?</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email">
              <Form.Label>E-Mail</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            {msg && (
              <Alert variant="success" onClose={() => setMsg("")} dismissible>
                {msg}
              </Alert>
            )}
            <div className="text-center mt-2">
              <Button disabled={loading} type="submit" className="w-50">
                Zurücksetzen
              </Button>
            </div>
          </Form>
        </Card.Body>
        <div className="w-100 text-center mt-2">
          <Link to={"/login"}>Zurück zum Login</Link>
        </div>
      </Card>
    </>
  );
};

export default PasswordReset;