import { useEffect, useState } from "react";
import { Card, Alert, Form, Button } from "react-bootstrap";
import { supabase } from "../supabase/client";
import { useAuth } from "../context/AuthProvider";
import UpdatePassword from "../components/UpdatePassword";
import { BarLoader } from "react-spinners";

const Profile = () => {
    const { user } = useAuth();

    const [errorMsg, setErrorMsg] = useState("");
    const [loadingErrorMsg, setLoadingErrorMsg] = useState("");
    const [loading, setLoading] = useState(true);

    const [username, setUsername] = useState(null);
    const [full_name, setFullName] = useState(null);

    useEffect(() => {
        let ignore = false;
        const getProfile = async () => {
            setLoading(true);

            const { data, error } = await supabase
                .from('profiles')
                .select(`username, full_name`)
                .eq('user_id', user.id)
                .single();

            if (!ignore) {
                if (error) {
                    setErrorMsg(error);
                } else if (data) {
                    setUsername(data.username);
                    setFullName(data.full_name);
                }
            }

            setLoading(false);
        }

        getProfile();

        return () => {
            ignore = true;
        };
    }, [user])

    const updateProfile = async (event) => {
        event.preventDefault();

        setLoading(true);

        const updates = {
            user_id: user.id,
            username,
            full_name,
            updated_at: new Date(),
        };

        const { error } = await supabase.from('profiles').upsert(updates);

        if (error) {
            setErrorMsg(error);
        }
        setLoading(false);
    }

    return (
        <>
            {loading && (<div className="d-flex align-items-center justify-content-center"
                style={{
                    minHeight: "20px",
                    flexGrow: "1"
                }}
            >
                <BarLoader />
                {loadingErrorMsg && (
                    <Alert
                        variant="danger"
                        onClose={() => setLoadingErrorMsg("")}
                        dismissible>
                        {loadingErrorMsg}
                    </Alert>
                )}
            </div>)}
            {!loading && (
                <>
                    <Card>
                        <Card.Body>
                            <h2 className="text-center my-4">Profil</h2>
                            {errorMsg && (
                                <Alert
                                    variant="danger"
                                    onClose={() => setErrorMsg("")}
                                    dismissible>
                                    {errorMsg}
                                </Alert>
                            )}
                            <Form onSubmit={updateProfile}>
                                <Form.Group id="email">
                                    <Form.Label>E-Mail</Form.Label>
                                    <Form.Control type="email" value={user?.email || ''} disabled />
                                </Form.Group>
                                <Form.Group id="username">
                                    <Form.Label>Benutzername</Form.Label>
                                    <Form.Control type="text" value={username || ''} onChange={(e) => setUsername(e.target.value)} />
                                </Form.Group>
                                <Form.Group id="full_name">
                                    <Form.Label>Vollständiger Name</Form.Label>
                                    <Form.Control type="text" value={full_name || ''} onChange={(e) => setFullName(e.target.value)} />
                                </Form.Group>
                                {errorMsg && (
                                    <Alert
                                        variant="danger"
                                        onClose={() => setErrorMsg("")}
                                        dismissible>
                                        {errorMsg}
                                    </Alert>
                                )}
                                <div className="text-center mt-2">
                                    <Button disabled={loading} type="submit" className="w-50">
                                        Setzen
                                    </Button>
                                </div>
                            </Form>
                            <UpdatePassword />
                        </Card.Body>
                    </Card>
                </>
            )}
        </>
    );
};

export default Profile;